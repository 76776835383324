<template>
  <div>
      <div class="logo-horizontal">
      <router-link to="/"
        ><img src="@/assets/horizontal.png" alt=""
      /></router-link>
    </div>
    <div class="container">
        <h3 v-if="!resp" class="ok">Sikeres E-mail cím érvényesítés!</h3>
        <h3 v-if="resp" class="fault">Sikertelen E-mail cím érvényesítés!</h3>
    <p><router-link to="/">Vissza a főoldalra</router-link></p>
    </div>
  </div>
</template>

<script>
import Api from "../util/api";

export default {
  name: "validation",
  data() {
      return {
          resp: ""
      }
  },
  created() {
    let self = this;
    Api.validation(self.$route.params.id)
      .then(function(response) {
        self.resp = response.data;
      })
      .catch(err => console.log(err));
  }
};
</script>

<style scoped>
.container {
  position: relative;
  max-width: 700px;
  margin: 10px auto;
  padding: 35px 35px 60px 35px;
  color: #444;
  background: white;
}
.logo-horizontal {
  width: 260px;
  margin: 0 auto;
  margin-top: 20px;
}
h3, p {
    text-align: center;
    margin-bottom: 30px;
}
</style>